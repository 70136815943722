import React from "react";

// Navbar
import Navbar from "components/Navbar";

// Secciones
import Section1 from "components/Section1";
import Section2 from "components/Section2";
import Section3 from "components/Section3";
import Section4 from "components/Section4";
import Section5 from "components/Section5";
import Section6 from "components/Section6";
import Footer from "components/Footer";
import Ball from "components/Ball";

// Meta Tags
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="bg-[#ECECEC] selection:bg-indigo-200  selection:text-[#6236FF]">
      <Helmet>
        <title>Con Quién Viajo</title>
        <link rel="icon" href="/Icon.png" />
        <meta name="description" content="Con Quién Viajo" />
        <meta name="theme-color" content="#6F439A" />
      </Helmet>

      <Navbar />

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />

      <Ball />
      <Footer />
    </div>
  );
}

export default App;
