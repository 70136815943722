import React from "react";

// Images
import Lock from "../images/Lock.svg";
import Clock from "../images/Clock.svg";
import Dolar from "../images/Dolar.svg";
import Star from "../images/Star.svg";
import Menu from "../images/Menu.svg";
import Arrow from "../images/Arrow.svg";

import GooglePlay from "../images/GooglePlay2.png";
import AppStore from "../images/AppStore.png";

// Form
import Form from "./Form";

// Animations
import { SwipeTopAnim } from "./ui/Animations";

const features = [
  {
    name: "Seguridad",
    description:
      "Los pasajeros podrán conocer los datos de la persona que los va a buscar.",
    icon: Lock,
  },
  {
    name: "Optimización del tiempo",
    description: "Localizacion automatica del movil mas cercano al pasajero.",
    icon: Clock,
  },
  {
    name: "Gratuito",
    description:
      "Los pasajeros pueden descargar la app de manera gratuita y con uso ilimitado.",
    icon: Dolar,
  },
  {
    name: "Sistema de calificaciones",
    description:
      "Consiga puntuacion y comentarios sobre el conductor y sobre la empresa.",
    icon: Star,
  },
  {
    name: "Historial de viajes",
    description:
      "Acceda a un reporte con el historial de viajes y detalles de los mismos.",
    icon: Menu,
  },
  {
    name: "Unifique pedidos",
    description:
      "Cargue a la plataforma viajes que llegan a través de llamado telefónico.",
    icon: Arrow,
  },
];

const Section5 = () => {
  return (
    <section className="w-screen h-full bg-white py-[140px]" id="app">
      <div className=" flex flex-col items-center justify-center ">
        <SwipeTopAnim delay={300} duration={700}>
          <p className="text-[#6236FF] text-md lg:text-xl font-medium leading-6  ">
            LA APP
          </p>
        </SwipeTopAnim>
        <SwipeTopAnim delay={500} duration={700}>
          <h2 className="text-[#201E21] px-10 mt-4 text-4xl font-black leading-8 lg:text-[48px] lg:w-[640px] text-center lg:leading-[58px] ">
            Características generales
          </h2>
        </SwipeTopAnim>
      </div>

      <div className="mt-24 lg:mt-[84px] px-10 md:px-20 lg:px-[100px] xl:px-[156px] 2xl:px-[300px] 3xl:px-[400px] max-w-[1920px] mx-auto ">
        <dl className="md:space-y-0 grid grid-cols-2 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-20 gap-x-12 gap-y-20 ">
          {features.map((feature, index) => (
            <SwipeTopAnim delay={600 + 100 * index} duration={700} key={index}>
              <div
                key={feature.name}
                className="flex flex-col items-start lg:items-center lg:flex-row w-full"
                data-testid="feature"
              >
                <div className="flex items-center lg:pr-8">
                  <img src={feature.icon} className="lg:w-8 lg:h-8 h-6 w-6" />
                </div>
                <div className="flex items-start mt-4 lg:mt-0 justify-center flex-col">
                  <h5 className="text-lg font-bold text-[#6236FF] lg:text-left leading-[25px] ">
                    {feature.name}
                  </h5>
                  <p className="text-base font-normal text-left lg:mt-0 mt-3 lg:text-left leading-[22px] ">
                    {feature.description}
                  </p>
                </div>
              </div>
            </SwipeTopAnim>
          ))}
        </dl>
      </div>

      <div className="pt-40" id="contacto">
        <div className=" flex flex-col items-center justify-center">
          <SwipeTopAnim delay={300} duration={700}>
            <p className="text-[#6236FF] text-md lg:text-xl font-medium leading-6  ">
              CONTACTO
            </p>
          </SwipeTopAnim>
          <SwipeTopAnim delay={500} duration={700}>
            <h2 className="text-[#201E21] px-10 mt-4 text-4xl font-black leading-8 lg:text-[48px] lg:w-[640px] text-center lg:leading-[58px] ">
              Dejanos tu consulta
            </h2>
          </SwipeTopAnim>
        </div>

        <div className="lg:max-w-[420px] max-w-xs mt-24 mx-auto">
          <SwipeTopAnim delay={300} duration={700}>
            <Form />
          </SwipeTopAnim>
        </div>
      </div>

      <div className="pt-[140px]">
        <div className=" flex flex-col items-center justify-center ">
          <SwipeTopAnim delay={300} duration={700}>
            <p className="text-[#6236FF] text-md lg:text-xl font-medium leading-6  ">
              COMENZÁ A USAR LA APP
            </p>
          </SwipeTopAnim>
          <SwipeTopAnim delay={500} duration={700}>
            <h2 className="text-[#201E21] px-10 mt-4 text-4xl font-black leading-8 lg:text-[48px] lg:w-[640px] text-center lg:leading-[58px] ">
              Enlaces de descarga
            </h2>
          </SwipeTopAnim>
        </div>

        <div className="flex flex-col items-center justify-center lg:flex-row mt-14 space-y-4 lg:space-x-5 lg:space-y-0">
          <SwipeTopAnim delay={500} duration={700}>
            <a
              href="https://play.google.com/store/apps/details?id=com.cqvpasajero&hl=es_AR&gl=US"
              data-testid="android"
            >
              <img
                src={GooglePlay}
                alt="Con Quien Viajo PlayStore"
                className="w-[200px]"
              />
            </a>
          </SwipeTopAnim>
          <SwipeTopAnim delay={600} duration={700}>
            <a
              href="https://apps.apple.com/ar/app/con-qui%C3%A9n-viajo/id1571926158"
              data-testid="ios"
            >
              <img
                src={AppStore}
                alt="Con Quien Viajo AppStore"
                className="w-[200px] h-[59px]"
              />
            </a>
          </SwipeTopAnim>
        </div>
      </div>
    </section>
  );
};

export default Section5;
