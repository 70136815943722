import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";

type AnimationType = {
  children: JSX.Element | JSX.Element[];
  delay?: number;
  duration?: number;
  cascade?: boolean;
};

export const customAnimation = keyframes`
from {
  opacity: 0;
  transform: translate3d(-30px, 0px, 0);
}
to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`;

export const customAnimationTop = keyframes`
from {
  opacity: 0;
  transform: translate3d(0px, 30px, 0);
}
to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`;

export const SwipeLeftAnim = ({
  delay,
  duration,
  children,
  cascade,
}: AnimationType) => (
  <Reveal
    keyframes={customAnimation}
    triggerOnce={true}
    delay={delay || 300}
    duration={duration || 500}
    cascade={cascade || true}
  >
    {children}
  </Reveal>
);

export const SwipeTopAnim = ({
  delay,
  duration,
  children,
  cascade,
}: AnimationType) => (
  <Reveal
    keyframes={customAnimationTop}
    triggerOnce={true}
    delay={delay || 300}
    duration={duration || 500}
    cascade={cascade || true}
  >
    {children}
  </Reveal>
);
