import React from "react";

import RemisSol from "../images/RemisSol.png";
import RemisConcepcion from "../images/RemisConcepcion.png";
import RemisTaxi from "../images/RemisTaxi.png";

const Section6 = () => {
  return (
    <section id="footer">
      <div className="w-screen flex items-center justify-center text-[#6236FF] text-md font-medium py-6 bg-[rgba(98,54,255,.1)]">
        CONFÍAN EN NOSOTROS
      </div>

      <div className="w-screen flex items-center justify-center space-x-6 px-5 md:space-x-20 py-8 md:py-10 bg-[rgba(98,54,255,.23)] ">
        <div className="flex flex-col" data-testid="client">
          <img src={RemisSol} alt="Con Quien Viajo Remis Sol Salta" />
          <div className="text-center mt-6">
            <p>Remises Sol</p>
            <b>Salta</b>
          </div>
        </div>

        <div
          className="flex flex-col items-center justify-center"
          data-testid="client"
        >
          <img
            src={RemisConcepcion}
            className="w-3/5"
            alt="Con Quien Viajo Remis Concepción Tucumán"
          />
          <div className="text-center mt-6">
            <p>Remises Concepción</p>
            <b>Tucumán</b>
          </div>
        </div>

        <div className="flex flex-col" data-testid="client">
          <img src={RemisTaxi} alt="Con Quien Viajo Remis Taxi Santa Fe" />
          <div className="text-center mt-6">
            <p>Radio Taxi</p>
            <b>Santa Fe</b>
          </div>
        </div>
      </div>

      <div className="bg-white w-screen h-[40px] "></div>
    </section>
  );
};

export default Section6;
