import { ChevronUpIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

const Ball = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  return (
    <div
      className={`${
        scrollPosition > 800 ? "bottom-8" : "-bottom-full"
      } fixed transition-all duration-1000 z-40 right-8`}
    >
      <a href="#" data-testid="goTop">
        <ChevronUpIcon className="w-14 h-14 bg-white p-2.5 text-[#6236FF] shadow-2xl rounded-full" />
      </a>
    </div>
  );
};

export default Ball;
