import React from "react";

// Image
import Phones from "../images/Phones.png";

// Animations
import { Fade } from "react-awesome-reveal";
import { SwipeLeftAnim } from "./ui/Animations";

const Section1 = () => {
  return (
    <section
      className="w-screen h-screen bg-gradient overflow-hidden 2xl:overflow-auto flex flex-col 2xl:flex-auto items-center sm:items-start lg:justify-center pt-40 md:pt-56 lg:pt-4 2xl:px-[130px]"
      id="#"
    >
      <div className="w-screen h-screen overlay absolute left-0 top-0"></div>
      <div className="relative 2xl:pt-16 z-30 2xl:px-0 px-5 sm:px-10 md:px-[40px] lg:px-[80px] ">
        <SwipeLeftAnim delay={600} duration={500}>
          <h1 className="font-extrabold text-4xl sm:text-5xl lg:text-6xl xl:text-7xl lg:leading-[60px] 3xl:text-[92px] 4xl:text-[140px] 4xl:leading-[160px] 3xl:leading-[102px] text-white xl:leading-[86px] 2xl:mb-[83px] mb-10 ">
            Pedir un móvil <br /> nunca fue tan fácil
          </h1>
        </SwipeLeftAnim>
        <SwipeLeftAnim delay={800} duration={500}>
          <a
            href="#contacto"
            className="text-lg px-10 font-semibold 2xl:text-[22px] text-[#6236FF] py-3 2xl:py-[17px] 2xl:w-[200px] transition-all duration-150 bg-white rounded-full leading-[26px] hover:drop-shadow-xl focus:bg-[#6236FF] focus:text-white focus:drop-shadow-none "
          >
            Contactanos
          </a>
        </SwipeLeftAnim>
      </div>

      <div className="flex justify-end items-end">
        <Fade delay={900} triggerOnce={true}>
          <img
            src={Phones}
            alt="Con Quien Viajo App"
            className="2xl:right-0 2xl:bottom-0 2xl:absolute 2xl:translate-y-12 2xl:translate-x-0 2xl:max-w-full 2xl:w-auto 3xl:w-[55%] 3xl:bottom-[8%] -translate-x-12 sm:-translate-x-0 sm:absolute sm:bottom-0 sm:right-0 md:w-[700px] lg:w-[750px] xl:w-[900px] max-w-5xl mt-8 w-[650px] translate-y-8 "
          />
        </Fade>
      </div>
    </section>
  );
};

export default Section1;
