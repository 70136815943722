import React from "react";

type ButtonType = {
  text: string;
  type: string;
  href: string | "#";
};

const Button: ({ text, type }: ButtonType) => JSX.Element = ({
  text,
  type,
  href,
}) => {
  return (
    <>
      {type === "small" && (
        <a
          href={href}
          className="w-[124px] font-medium flex items-center justify-center py-3 border-[1px] transition-all duration-150 rounded-full border-white hover:bg-[rgba(255,255,255,.23)] hover:drop-shadow-xl focus:bg-white focus:text-[#6236FF] focus:drop-shadow-none "
        >
          {text}
        </a>
      )}
      {type === "navbar" && (
        <a
          href={href}
          className="text-[18px] transition-all duration-150 hover:text-shadow-xl focus:text-black focus:text-shadow-none"
        >
          {text}
        </a>
      )}
      {type === "navbarSide" && (
        <a
          href={href}
          className="text-[18px] transition-all duration-150 hover:text-[#8d6eff] focus:text-black focus:text-shadow-none"
        >
          {text}
        </a>
      )}
    </>
  );
};

export default Button;
