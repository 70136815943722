import React from "react";

import Chofer1 from "../images/Chofer1.png";
import Chofer2 from "../images/Chofer2.png";
import Chofer3 from "../images/Chofer3.png";
import Chofer4 from "../images/Chofer4.png";
import Chofer5 from "../images/Chofer5.png";
import Chofer6 from "../images/Chofer6.png";

// Swiper
import {
  Swiper,
  SwiperSlide,
} from "../../node_modules/swiper/react/swiper-react";
import { Autoplay } from "swiper";
import "swiper/css";

import { SwipeTopAnim } from "./ui/Animations";

const Section4 = () => {
  return (
    <section
      className="bg-[#ECECEC] px-10 h-full max-w-[1920px] mx-auto pt-[106px] pb-20 "
      id="pasajeros"
      data-testid="section4"
    >
      <div className=" flex flex-col items-center justify-center">
        <SwipeTopAnim delay={300} duration={700}>
          <p className="text-[#6236FF] text-md lg:text-xl font-medium leading-6  ">
            CONDUCTORES
          </p>
        </SwipeTopAnim>
        <SwipeTopAnim delay={500} duration={700}>
          <h2 className="text-[#201E21] mt-4 text-4xl font-black leading-8 lg:text-[48px] lg:w-[640px] text-center lg:leading-[58px] ">
            Aplicación para choferes
          </h2>
        </SwipeTopAnim>
      </div>

      <SwipeTopAnim delay={300} duration={700}>
        <div className="xl:px-[208px] lg:px-20 w-full  mt-20 lg:mt-36 ">
          <div className="lg:grid lg:items-start xl:items-end lg:grid-cols-5">
            <div className="text-lg font-normal xl:text-left lg:h-full lg:flex lg:flex-col lg:justify-center xl:pr-16 col-span-2 leading-7 lg:w-[280px] xl:w-full ">
              <p>
                Los choferes pueden iniciar, aceptar o delegar viajes,
                manteniendo un chat con la base o los pasajeros (globo-tag).
              </p>
              <p className="mt-10">
                Desde la aplicación puede mantener un registro en tiempo real de
                la ubicación del punto de encuentro con el pasajero; y notificar
                ausencia del mismo en dicho punto.
              </p>
            </div>

            <div className="md:hidden block mt-12 w-full">
              <Swiper
                slidesPerView={1.5}
                spaceBetween={20}
                loop={false}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img
                    src={Chofer1}
                    alt="Con Quien Viajo Conductor Demo"
                    className="rounded-xl border-[1px]"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={Chofer2}
                    alt="Con Quien Viajo Conductor Demo"
                    className="rounded-xl border-[1px]"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={Chofer3}
                    alt="Con Quien Viajo Conductor Demo"
                    className="rounded-xl border-[1px]"
                  />
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="hidden md:grid lg:mt-0 mt-12 md:grid-cols-3 col-span-3 grid-rows-1 md:gap-[20px] ">
              <img
                src={Chofer1}
                alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                className="h-full w-full shadow-2xl shadow-indigo-600/20 rounded-xl"
              />
              <img
                src={Chofer2}
                alt="Top down view of walnut card tray with embedded magnets and card groove."
                className="h-full w-full shadow-2xl shadow-indigo-600/20 rounded-xl"
              />
              <img
                src={Chofer3}
                alt="Side of walnut card tray with card groove and recessed card area."
                className="h-full w-full shadow-2xl shadow-indigo-600/20 rounded-xl"
              />
            </div>
          </div>
        </div>

        <div className="xl:px-[208px] lg:px-20  mt-20 lg:grid lg:grid-cols-5 lg:grid-rows-1 ">
          <div className="hidden lg:-translate-x-16 col-span-3 md:grid grid-cols-3 grid-rows-1 gap-[20px] ">
            <img
              src={Chofer4}
              alt="Con Quien Viajo Conductor Demo"
              className="h-full w-full shadow-2xl shadow-indigo-600/20 rounded-xl"
            />
            <img
              src={Chofer5}
              alt="Con Quien Viajo Conductor Demo"
              className="h-full w-full shadow-2xl shadow-indigo-600/20 rounded-xl"
            />
            <img
              src={Chofer6}
              alt="Con Quien Viajo Conductor Demo"
              className="h-full w-full shadow-2xl shadow-indigo-600/20 rounded-xl"
            />
          </div>

          <div className="md:hidden block mt-20 w-full">
            <Swiper
              slidesPerView={1.5}
              spaceBetween={20}
              loop={false}
              autoplay={{
                delay: 1800,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <img
                  src={Chofer4}
                  alt="Con Quien Viajo Conductor Demo"
                  className="rounded-xl border-[1px]"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={Chofer5}
                  alt="Con Quien Viajo Conductor Demo"
                  className="rounded-xl border-[1px]"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={Chofer6}
                  alt="Con Quien Viajo Conductor Demo"
                  className="rounded-xl border-[1px]"
                />
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="text-lg font-normal mt-12 lg:h-full lg:flex lg:flex-col lg:justify-center lg:mt-0 col-span-2 leading-7 lg:w-[380px]  ">
            <p>
              Cuenta con alertas sonoras cuando hay un nuevo viaje y la
              posibilidad de establecer un modo offline temporalmente.
            </p>
            <br />
            <p className="mt-5">
              A su vez incluye un tarifario, para estimar el costo de un viaje
              en base al “desde” y “hasta”, asistido por el autocompletado de
              Google Maps.
            </p>
          </div>
        </div>
      </SwipeTopAnim>
    </section>
  );
};

export default Section4;
