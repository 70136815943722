import React from "react";

// Images
import Place from "../images/Place.svg";
import Car from "../images/Car.svg";
import Clue from "../images/Clue.svg";
import Phone1 from "../images/Phone1.png";
import Phone2 from "../images/Phone2.png";
import Phone3 from "../images/Phone3.png";

// Swiper
import {
  Swiper,
  SwiperSlide,
} from "../../node_modules/swiper/react/swiper-react";
import { Autoplay } from "swiper";
import "swiper/css";

// Animations
import { SwipeTopAnim } from "./ui/Animations";

const Section2 = () => {
  return (
    <section
      className="bg-[#ECECEC] h-full max-w-[1920px] mx-auto px-5 lg:px-0 pt-[146px] "
      id="pasajeros"
    >
      <div className="flex flex-col items-center justify-center">
        <SwipeTopAnim delay={300} duration={700}>
          <p className="text-[#6236FF] text-md lg:text-xl font-medium leading-6 ">
            PASAJEROS
          </p>
        </SwipeTopAnim>

        <SwipeTopAnim delay={500} duration={700}>
          <h2 className="text-[#201E21] mt-2 lg:mt-4 text-5xl lg:text-[64px] font-black leading-[77px] ">
            ¿Qué es CQV?
          </h2>
        </SwipeTopAnim>

        <SwipeTopAnim delay={700} duration={700}>
          <p className="text-[#201E21] text-xl font-medium lg:w-[580px] mt-7 text-center ">
            Con Quién Viajo es una aplicación para dispositivos móviles
            totalmente gratuita.
          </p>
        </SwipeTopAnim>
      </div>

      <SwipeTopAnim delay={900} duration={700} cascade>
        <div className="xl:px-[208px] 2xl:px-[300px] 3xl:px-[400px] lg:px-20 md:px-10 px-5 items-center space-y-20 lg:space-y-0 lg:space-x-12 flex flex-col lg:flex-row flex-auto lg:justify-between mt-20 ">
          <div
            className="flex flex-col items-center lg:w-[440px] md:w-[400px] justify-start"
            data-testid="feature"
          >
            <div className="w-[120px] h-[120px] rounded-full flex justify-center items-center img-gradient ">
              <img
                src={Place}
                alt="Con Quien Viajo App Posicion"
                className=""
              />
            </div>
            <p className="text-center px-2 mt-8 text-lg font-normal leading-[27px] ">
              Permite a los pasajeros realizar la reserva del taxi o remis más
              cercano a su posición, acortando los tiempos de espera.
            </p>
          </div>

          <div
            className="flex flex-col items-center lg:w-[440px] md:w-[400px] justify-start"
            data-testid="feature"
          >
            <div className="w-[120px] h-[120px] rounded-full flex justify-center items-center img-gradient ">
              <img src={Car} alt="Con Quien Viajo Conductor" className="" />
            </div>
            <p className="text-center mt-8 text-lg font-normal  leading-[27px] ">
              También pueden ver en tiempo real la ubicación del conductor
              mientras está acudiendo al viaje y durante el mismo.
            </p>
          </div>

          <div
            className="flex flex-col items-center lg:w-[440px] md:w-[400px] justify-center"
            data-testid="feature"
          >
            <div className="w-[120px] h-[120px] rounded-full flex justify-center items-center img-gradient ">
              <img src={Clue} alt="Con Quien Viajo Empresas" className="" />
            </div>
            <p className="text-center mt-8 text-lg font-normal  leading-[27px] ">
              Y para sentirse más seguros pueden seleccionar su empresa favorita
              o simplemente tomar un viaje con el vehículo más cercano.
            </p>
          </div>
        </div>
      </SwipeTopAnim>

      <div className="mt-36 md:px-10 xl:px-[208px] 2xl:px-[300px] 3xl:px-[400px] lg:px-20 px-5 pb-28 lg:pb-64 lg:grid lg:grid-cols-2">
        <div>
          <SwipeTopAnim delay={500} duration={700}>
            <h2 className="text-4xl lg:text-5xl font-black mt-20 text-[#201E21] leading-[58px] ">
              ¿Cómo funciona?
            </h2>
          </SwipeTopAnim>
          <SwipeTopAnim delay={600} duration={700}>
            <p className="lg:w-[430px] mt-12 lg:mt-[68px] text-lg font-normal leading-[27px] ">
              <b> Es muy fácil.</b> Cuando los pasajeros se loguean en la
              aplicación, se activa su posición en un mapa. Al presionar
              "reservar" pueden ingresar la información, para que el sistema
              busque el móvil más cercano.
            </p>
          </SwipeTopAnim>
          <br />
          <SwipeTopAnim delay={700} duration={700}>
            <p className="lg:w-[430px] mt-6 lg:mt-12 text-lg font-normal leading-[27px] ">
              Cuando el conductor acepte el viaje, podrán ver su posición en
              tiempo real, su perfil personal y los datos del vehículo, como así
              también mantener un chat (globos-tag) de seguimiento.
            </p>
          </SwipeTopAnim>
        </div>

        <div className="md:relative hidden md:grid md:grid-cols-3 md:gap-4 md:mt-12 lg:mt-0">
          <img
            src={Phone1}
            alt="Con Quien Viajo App Demo"
            className="lg:absolute lg:w-[240px] rounded-xl left-1 z-10 -bottom-32 shadow-xl shadow-purple-400/20"
          />
          <img
            src={Phone2}
            alt="Con Quien Viajo App Demo"
            className="lg:absolute lg:w-[240px] rounded-xl left-1/4 z-20 -top-6 shadow-xl shadow-purple-400/20"
          />
          <img
            src={Phone3}
            alt="Con Quien Viajo App Demo"
            className="lg:absolute lg:w-[240px] rounded-xl left-[55%] z-10 -bottom-28 shadow-xl shadow-purple-400/20"
          />
        </div>

        <SwipeTopAnim delay={700} duration={700}>
          <div className="md:hidden block mt-20 w-full">
            <Swiper
              slidesPerView={1.5}
              spaceBetween={20}
              loop={false}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <img
                  src={Phone1}
                  alt="Con Quien Viajo App Demo"
                  className="rounded-xl border-[1px]"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={Phone2}
                  alt="Con Quien Viajo App Demo"
                  className="rounded-xl border-[1px]"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={Phone3}
                  alt="Con Quien Viajo App Demo"
                  className="rounded-xl border-[1px]"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </SwipeTopAnim>
      </div>
    </section>
  );
};

export default Section2;
