import React from "react";

import Icon from "../images/Icon.svg";
import { SwipeTopAnim } from "./ui/Animations";

const features = [
  {
    description: (
      <p>
        Obtenga <b>reservas anticipadas</b> , viajes programados o de
        determinadas caracteristicas, de pasajeros recurrentes o nuevo
        pasajeros.
      </p>
    ),
  },
  {
    description: (
      <p>
        <b>Asigne un chofer</b> particular o un pool de choferes ordenados en
        base a la cercanía de quien pide el viaje.
      </p>
    ),
  },
  {
    description: (
      <p>
        Dé de <b>alta conductores</b> y vehiculos, con sus respectivos perfiles,
        informacion y notas.
      </p>
    ),
  },

  {
    description: (
      <p>
        Indique <b>parámetros de costo</b> por kilómetro o bajada de bandera y
        mantenga chats con los conductores.
      </p>
    ),
  },
];

const Section3 = () => {
  return (
    <section
      className="relative flex h-auto w-screen overflow-x-hidden"
      id="empresas"
    >
      <div className="overlay-section3 absolute left-0 z-20 w-full h-full"></div>
      <div className="image-section3 absolute top-0 z-10"></div>

      <div className="w-full px-5  z-30 flex flex-col items-center py-[140px] ">
        <SwipeTopAnim delay={300} duration={700}>
          <p className="text-[#6236FF] text-md lg:text-xl font-medium leading-6 ">
            EMPRESAS
          </p>
        </SwipeTopAnim>
        <SwipeTopAnim delay={500} duration={700}>
          <h2 className="text-[#201E21] mt-4 text-4xl font-black leading-8 lg:text-[48px] lg:w-[640px] text-center lg:leading-[58px] ">
            Gestione su flota con el panel de administrador
          </h2>
        </SwipeTopAnim>

        <div className="mt-20 px-5 lg:px-[208px] w-full flex items-center justify-center ">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-16">
            {features.map((feature, index) => (
              <SwipeTopAnim
                delay={500 + 100 * index}
                duration={700}
                key={index}
              >
                <div
                  className="relative flex items-start space-x-5"
                  data-testid="advantage"
                >
                  <img src={Icon} alt="Con Quien Viajo Icono" />
                  <div className="lg:w-[320px] text-lg leading-[22px] ">
                    {feature.description}
                  </div>
                </div>
              </SwipeTopAnim>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default Section3;
