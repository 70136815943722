import {
  LocationMarkerIcon,
  PhoneIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import React from "react";
import Logo from "../images/CQVLogo.svg";

const Footer = () => {
  return (
    <>
      <footer className="w-screen bg-gradient ">
        <div className="max-w-[1920px] mx-auto py-10 px-5 lg:px-0 flex flex-col lg:flex-row items-start lg:items-center justify-around ">
          <div>
            <img src={Logo} className="w-[90%] " alt="Con Quien Viajo Logo" />
          </div>

          <div className="flex flex-col mt-6 lg:mt-0 lg:flex-row items-start lg:items-center justify-center lg:space-y-0 space-y-3 lg:space-x-12">
            <div
              className="flex space-x-5 text-white justify-center items-center"
              data-testid="phoneNumber"
            >
              <PhoneIcon className="w-10 p-2 h-10 rounded-full bg-[#ffffff65] " />
              <div className="flex flex-col">
                <h5 className="font-bold">Comercial</h5>
                <p className="text-white">+54 342 4625609</p>
              </div>
            </div>

            <div
              className="flex space-x-5 text-white justify-center items-center"
              data-testid="phoneNumber"
            >
              <SupportIcon className="w-10 p-2 h-10 rounded-full bg-[#ffffff65] " />
              <div className="flex flex-col">
                <h5 className="font-bold">Soporte</h5>
                <p className="text-white">+54 342 4121195</p>
              </div>
            </div>

            <div
              className="flex space-x-5 text-white justify-center items-center"
              data-testid="phoneNumber"
            >
              <LocationMarkerIcon className="w-10 p-2 h-10 rounded-full bg-[#ffffff65] " />
              <div className="flex flex-col">
                <h5 className="font-bold">San Jerónimo 2542 PB</h5>
                <p className="text-white">Oficina 2</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="w-screen bg-white flex items-center justify-center py-3">
        <p className="font-semibold text-sm ">2022 · Con Quién Viajo</p>
      </div>
    </>
  );
};

export default Footer;
