import React, { Fragment, useState, useReducer, FormEvent } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline";
import formReducer, { FormData } from "reducer/FormReducer";

import emailjs from "@emailjs/browser";

const options = [
  { id: 1, name: "Sin flota", unavailable: false },
  { id: 2, name: "Entre 1 y 30", unavailable: false },
  { id: 3, name: "Entre 31 y 60", unavailable: false },
  { id: 4, name: "Entre 61 y 100", unavailable: false },
  { id: 5, name: "Más de 100", unavailable: true },
];

const initialFormData: FormData = {
  nombre: "",
  asunto: "",
  email: "",
  flota: "Sin Flota",
  mensaje: "",
  premios: false,
  demo: false,
};

const Form = () => {
  const [selected, setSelected] = useState(options[0]);
  const [formData, dispatch] = useReducer(formReducer, initialFormData);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_KEY as string,
        process.env.REACT_APP_TEMPLATE_KEY as string,
        formData,
        process.env.REACT_APP_USER_KEY
      )
      .then(
        () => {
          alert("Mensaje enviado correctamente!");
          dispatch({
            type: "vaciar",
            payload: null,
          });
        },
        (error) => {
          alert("Error: " + error);
        }
      );
    emailjs.send(
      process.env.REACT_APP_SERVICE_KEY as string,
      process.env.REACT_APP_TEMPLATE_CLIENT_KEY as string,
      formData,
      process.env.REACT_APP_USER_KEY
    );
  };

  const handleBox = (e: any) => {
    setSelected(e);
    dispatch({
      type: "flota",
      payload: e.name,
    });
  };

  return (
    <form className="space-y-[32px]" onSubmit={handleSubmit} data-testid="form">
      <input
        type="text"
        required
        value={formData.nombre}
        className="appearance-none w-full border-b-[1px] border-[rgba(98,54,255,.66)] text-center focus:border-[rgba(98,54,255,1)] focus-visible:ring-outline-none focus:outline-none placeholder:text-[16px] placeholder:opacity-[.66] "
        placeholder="Nombre y Apellido"
        onChange={(e) => {
          dispatch({
            type: "nombre",
            payload: e.target.value,
          });
        }}
      />
      <input
        type="email"
        required
        value={formData.email}
        className="appearance-none w-full border-b-[1px] border-[rgba(98,54,255,.66)] text-center focus:border-[rgba(98,54,255,1)] focus-visible:ring-outline-none focus:outline-none placeholder:text-[16px]"
        placeholder="E-mail"
        onChange={(e) => {
          dispatch({
            type: "email",
            payload: e.target.value,
          });
        }}
      />
      <input
        type="text"
        required
        value={formData.asunto}
        className="appearance-none w-full border-b-[1px] border-[rgba(98,54,255,.66)] text-center focus:border-[rgba(98,54,255,1)] focus-visible:ring-outline-none focus:outline-none placeholder:text-[16px]"
        placeholder="Asunto"
        onChange={(e) => {
          dispatch({
            type: "asunto",
            payload: e.target.value,
          });
        }}
      />

      <Listbox value={selected} onChange={handleBox}>
        <div className="relative">
          <Listbox.Button className="relative w-full pb-1 px-4 text-center border-b-[1px] border-[rgba(98,54,255,.66)] focus:border-[rgba(98,54,255,1)] cursor-pointer focus:outline-none sm:text-sm">
            <span data-testid="listOption" className="block truncate">
              {selected.name}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 text-gray-500"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? "text-indigo-600 bg-indigo-100" : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      <textarea
        cols={1}
        rows={7}
        required
        value={formData.mensaje}
        placeholder="Mensaje"
        className="resize-none w-full border-[1px] px-4 py-2 rounded-[5px] text-center border-[rgba(98,54,255,.66)] focus:border-[rgba(98,54,255,1)] focus-visible:ring-outline-none focus:outline-none"
        onChange={(e) => {
          dispatch({
            type: "mensaje",
            payload: e.target.value,
          });
        }}
      ></textarea>

      <div>
        <label className="relative text-[#201e2181] text-[14px] flex items-center mb-2">
          <input
            className="form-check-input appearance-none transition-all h-5 w-5 rounded-md border border-[#6236FF] bg-white checked:bg-[#6236FF] checked:border-[#6236FF] focus:outline-none duration-200 bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            id="flexCheckDefault"
            data-testid="checkbox"
            onChange={(e) => {
              dispatch({
                type: "premios",
                payload: e.target.checked,
              });
            }}
          />
          <CheckIcon className="w-4 h-4 absolute text-white left-[2px] " />
          Me interesa conocer premios y planes
        </label>

        <label className="relative text-[#201e2181] text-[14px] flex items-center">
          <input
            className="form-check-input appearance-none transition-all h-5 w-5 rounded-md border border-[#6236FF] bg-white checked:bg-[#6236FF] checked:border-[#6236FF] focus:outline-none duration-200 bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            onChange={(e) => {
              dispatch({
                type: "demo",
                payload: e.target.checked,
              });
            }}
          />
          <CheckIcon className="w-4 h-4 absolute text-white left-[2px] " />
          Quiero una demo gratuita de la herramienta
        </label>
      </div>

      <button className="w-full rounded-full border-[1px] border-[#6236FF] text-lg py-4 text-[#6236FF] font-medium hover:bg-[#ffffffc2] transition-all duration-150 hover:drop-shadow-xl focus:bg-[#6236FF] focus:text-white focus:drop-shadow-none">
        Enviar consulta
      </button>
    </form>
  );
};

export default Form;
