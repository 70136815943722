export type FlotaType =
  | "Sin Flota"
  | "Entre 1 y 30"
  | "Entre 31 y 60"
  | "Entre 61 y 100"
  | "Mas de 100";

export type FormData = {
  nombre: string;
  email: string;
  asunto: string;
  flota: FlotaType;
  mensaje: string;
  premios: boolean;
  demo: boolean;
};

function formReducer(
  formData: FormData,
  {
    type,
    payload,
  }: {
    type: keyof FormData | "vaciar";
    payload: string | number | FlotaType | boolean | null;
  }
) {
  switch (type) {
    case "nombre":
      return {
        ...formData,
        nombre: payload as string,
      };
    case "email":
      return {
        ...formData,
        email: payload as string,
      };
    case "asunto":
      return {
        ...formData,
        asunto: payload as string,
      };
    case "flota":
      return {
        ...formData,
        flota: payload as FlotaType,
      };
    case "mensaje":
      return {
        ...formData,
        mensaje: payload as string,
      };
    case "premios":
      return {
        ...formData,
        premios: payload as boolean,
      };
    case "demo":
      return {
        ...formData,
        demo: payload as boolean,
      };
    case "vaciar":
      return {
        ...(formData = {
          nombre: "",
          email: "",
          asunto: "",
          flota: "Sin Flota",
          mensaje: "",
          premios: false,
          demo: false,
        }),
      };
  }
}

export default formReducer;
